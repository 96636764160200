.twitter-picker {
  @apply !shadow-none;
}
.twitter-picker > div:last-child {
  @apply !p-0;
}
.twitter-picker > div:last-child input {
  @apply !h-[30px];
}
.circle-picker {
  @apply !w-2/3;
}
.circle-picker > span > div {
  @apply !h-7 !w-7 !mr-2 !mb-2;
}
.circle-picker > span > div > span > div {
  @apply !rounded;
}
.block-picker {
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px !important;
}
.rdw-editor-wrapper {
  @apply border rounded-lg overflow-hidden;
}
.rdw-editor-wrapper .rdw-editor-toolbar {
  @apply border-0 border-b;
}
.rdw-editor-wrapper .rdw-editor-main {
  @apply overflow-hidden;
}
.rdw-editor-wrapper .rdw-editor-main .DraftEditor-root {
  @apply py-2 px-4 h-56 overflow-auto box-content;
}
.rdw-editor-wrapper .rdw-editor-main .public-DraftStyleDefault-block {
  @apply m-0;
}

/* LIST BLOCKS */
.szh-accordion {
  @apply bg-white;
}
.szh-accordion .szh-accordion__item {
  @apply relative border border-b-0 border-gray-300 first:rounded-t-lg last:border-b last:rounded-b-lg;
}
.szh-accordion .szh-accordion__item .szh-accordion__item-heading {
  @apply relative bg-gray-50;
}
.szh-accordion .szh-accordion__item .szh-accordion__item-heading input {
  @apply w-full text-gray-700 placeholder:text-gray-300;
}
.szh-accordion .szh-accordion__item:first-child .szh-accordion__item-heading,
.szh-accordion .szh-accordion__item:first-child .szh-accordion__item-heading .szh-accordion__item-btn {
  @apply rounded-t-lg;
}
.szh-accordion .szh-accordion__item:last-child .szh-accordion__item-heading,
.szh-accordion .szh-accordion__item:last-child .szh-accordion__item-heading .szh-accordion__item-btn {
  @apply rounded-b-lg;
}
.szh-accordion .szh-accordion__item .szh-accordion__item-heading .szh-accordion__item-btn {
  @apply flex items-center justify-between w-full py-1 px-3 font-normal text-sm text-left text-gray-500 bg-gray-100 hover:text-gray-900 hover:bg-gray-100 transition-all ease-in-out duration-300;
}
.szh-accordion .szh-accordion__item.szh-accordion__item--expanded .szh-accordion__item-heading .szh-accordion__item-btn {
  @apply bg-gray-100 text-gray-900; 
}
.szh-accordion .szh-accordion__item .szh-accordion__item-content {
  @apply p-5 border-t border-gray-300 bg-transparent;
}

/* MULTIMEDIA FILTERS */
.filters-media .block.mb-3 {
 @apply mb-0; 
}
.filters-media .search-input {
  @apply flex-1;
}
.filters-media .search-input .block {
  @apply w-full;
}
.filters-media .block input[type="text"] {
  @apply w-full bg-transparent border-none pl-8 -ml-4 rounded-none focus:ring-0 focus:outline-none;
}
.filters-media .block button {
  @apply bg-white rounded-md py-1.5 shadow-sm text-xs min-w-[180px] focus:outline-none;
}
.filters-media .block ul li {
  @apply pl-4;
}
.filters-media .block ul li span.absolute {
  @apply hidden;
}
.filters-media .block ul li[aria-selected=true] {
  @apply bg-blue-600 text-white;
}
.upload-zone-buttons .outer-dropdown > div:first-child {
  @apply h-9;
}
.upload-zone-buttons .outer-dropdown .mt-2 {
  @apply mt-4;
}
.upload-zone-buttons .btn-icon {
  @apply w-auto h-full px-1.5 rounded-none hover:bg-gray-50;
}

/* MULTIMEDIA GALLERY */
.file-alternative-text:not(.edition-mode) textarea {
  @apply border-0 px-0 py-0 h-10 resize-none text-gray-500 disabled:bg-transparent placeholder:italic placeholder:text-sm placeholder:text-gray-500;
}
.file-alternative-text.edition-mode textarea {
  @apply p-2 resize-none;
}
.file-title:not(.edition-mode) textarea {
  @apply border-0 p-0 h-auto text-lg font-medium text-gray-900 resize-none disabled:bg-transparent placeholder:italic placeholder:text-lg placeholder:text-gray-500;
}
.file-title.edition-mode textarea {
  @apply p-2 resize-none;
}

/* DATE PICKER */
.react-datepicker {
  @apply border-0 shadow-xl font-poppins z-10;
}
.react-datepicker__header {
  @apply px-4 pt-3 pb-1 bg-primary-600 text-white;
}
 /* ====== MAIN NAVIGATION ====== */
.react-datepicker__year-read-view--down-arrow, 
.react-datepicker__month-read-view--down-arrow, 
.react-datepicker__month-year-read-view--down-arrow, 
.react-datepicker__navigation-icon::before {
  @apply border-white transition-all duration-300 ease-in-out;
}
.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
 @apply top-3.5; 
}
 /* ====== CURRENT MONTH AND YEAR ====== */
.react-datepicker__current-month {
  @apply text-white; 
 }
 /* ====== MONTH AND YEAR PICK====== */
.react-datepicker__year-read-view--down-arrow, 
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  @apply top-1 -right-3.5 border-t border-r h-1.5 w-1.5;
}
.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  @apply border rounded-none bg-white text-gray-600;
}
.react-datepicker__month-dropdown .react-datepicker__month-option,
.react-datepicker__year-dropdown .react-datepicker__year-option {
  @apply leading-5 hover:bg-gray-50
}
.react-datepicker__month-dropdown .react-datepicker__month-option.react-datepicker__month-option--selected_month,
.react-datepicker__year-dropdown .react-datepicker__year-option.react-datepicker__year-option--selected_year {
  @apply bg-gray-200 hover:bg-gray-200;
}
/* ====== WEEKDAYS NAME HEADER ====== */
.react-datepicker__day-names {
  @apply mt-2;
}
.react-datepicker__day-names .react-datepicker__day-name {
  @apply text-gray-200;
}

/* ====== TRIANGLE ====== */
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, 
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, 
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
  @apply border-b-primary-600;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  @apply right-0 left-0 m-auto !transform !translate-x-0 !translate-y-[-7px];
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  @apply right-0 left-0 m-auto !transform !translate-x-0 !translate-y-[8px];
}
/* ====== CALENDAR ====== */
.react-datepicker__day, 
.react-datepicker__time-name,
.react-datepicker-year-header,
.react-datepicker-time__header {
  @apply text-gray-600 transition-all duration-300 ease-in-out;
}
.react-datepicker__day.react-datepicker__day--outside-month {
  @apply text-gray-300;
}
.react-datepicker__day.react-datepicker__day--today {
  @apply text-gray-800 font-bold;
}
.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__month-text--selected, 
.react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--in-range, 
.react-datepicker__quarter-text--selected, 
.react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker__quarter-text--in-range, 
.react-datepicker__year-text--selected, 
.react-datepicker__year-text--in-selecting-range, 
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected, 
.react-datepicker__month-text--keyboard-selected, 
.react-datepicker__quarter-text--keyboard-selected, 
.react-datepicker__year-text--keyboard-selected {
  @apply bg-primary-500 !text-white rounded-md;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover, 
.react-datepicker__day--in-range:hover, 
.react-datepicker__month-text--selected:hover, 
.react-datepicker__month-text--in-selecting-range:hover, 
.react-datepicker__month-text--in-range:hover, 
.react-datepicker__quarter-text--selected:hover, 
.react-datepicker__quarter-text--in-selecting-range:hover, 
.react-datepicker__quarter-text--in-range:hover, 
.react-datepicker__year-text--selected:hover, 
.react-datepicker__year-text--in-selecting-range:hover, 
.react-datepicker__year-text--in-range:hover {
  @apply bg-indigo-600;
}
/* ====== TIME ====== */

.react-datepicker__input-time-container {
  @apply flex flex-col m-0 box-border px-6 pt-2 pb-4 clear-both;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  @apply mt-1;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  @apply ml-0 w-full;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input .react-datepicker-time__input {
  @apply w-full rounded-lg border border-slate-400 hover:border-slate-600
}


.react-datepicker__input-container input {
  @apply rounded-l-none disabled:text-slate-400 disabled:hover:border-gray-300 disabled:bg-gray-100;
}
.react-datepicker-wrapper {
  @apply max-w-[209px];
}

/* DROPZONE */
.dropzone.isDragActive {
  @apply relative before:transition-all before:duration-500 before:content-[""] before:absolute before:-inset-2 before:bg-blue-600/50 before:border-2 before:border-dashed before:rounded-xl after:content-["DragHere"] after:absolute after:text-3xl after:text-white after:font-bold after:top-28 after:left-1/2 after:-translate-x-1/2;
}
.dropzone.isDragActive section {
  @apply transition-all duration-500 opacity-20;
}

/* INPUT BUTTON */
.input-button-field > * {
  @apply flex-1;
}

/* INPUT LOADMORE */
div.input-loadmore__outer {
  @apply block relative;
}
div.input-loadmore__control {
  @apply relative w-full cursor-pointer text-sm min-h-0 rounded-lg bg-gray-50 border border-gray-300 py-2.5 pl-2.5 pr-10 text-left hover:border-gray-400 !ring-0 !outline-none aria-expanded:border-blue-700 transition-all ease-in-out duration-300;
}
div.input-loadmore__outer.input-loadmore__outer--is-multi .input-loadmore__control {
  @apply py-0.5 min-h-[42px] transition-none transition-transform;
}
div.input-loadmore__outer.input-loadmore__outer--error div.input-loadmore__control {
  @apply border-red-600 hover:border-red-600 aria-expanded:border-red-600
}
div.input-loadmore__control.input-loadmore__control--is-focused {
  @apply border-blue-700 focus-visible:border-0;
}
div.input-loadmore__control .input-loadmore__value-container {
  @apply p-0;
}
div.input-loadmore__control .input-loadmore__value-container .input-loadmore__input-container {
  @apply m-0 p-0;
}
div.input-loadmore__control .input-loadmore__indicators .input-loadmore__indicator-separator {
  @apply hidden;
}
div.input-loadmore__control .input-loadmore__indicators .input-loadmore__indicator.input-loadmore__dropdown-indicator {
  @apply hidden;
}
div.input-loadmore__menu .input-loadmore__menu-list {
  @apply rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm;
}
div.input-loadmore__menu .input-loadmore__menu-list .input-loadmore__option {
  @apply relative font-normal cursor-pointer select-none py-2 pl-10 pr-4 transition-all ease-in-out duration-300 text-gray-900;
}
div.input-loadmore__menu .input-loadmore__menu-list .input-loadmore__option.input-loadmore__option--is-selected {
  @apply font-medium bg-white; 
}
div.input-loadmore__menu .input-loadmore__menu-list .input-loadmore__option.input-loadmore__option--is-focused {
  @apply bg-blue-100 text-blue-900;
}

/* EDITOR INPUT */
/* .input-editor {
  @apply overflow-hidden
} */
.input-editor .menu-bar {
  @apply px-2 py-2 border border-b-0 rounded-t-lg bg-gray-100 z-[1] flex flex-wrap gap-2 relative;
}
.input-editor .menu-bar > div > button,
.input-editor .menu-bar > div > div {
  @apply border border-l-0 bg-white/50 py-1 px-2 hover:bg-white first:rounded-l-md last:rounded-r-md first:border-l disabled:opacity-30;
}
.input-editor .menu-bar .menu-items:hover button {
  @apply opacity-30
}
.input-editor .menu-bar .menu-items button {
  @apply block w-full text-left p-1 leading-normal hover:opacity-100;
}
.input-editor .menu-bar .menu-items .item-h1 {
  @apply text-[28px];
}
.input-editor .menu-bar .menu-items .item-h2 {
  @apply text-[26px];
}
.input-editor .menu-bar .menu-items .item-h3 {
  @apply text-[24px];
}
.input-editor .menu-bar .menu-items .item-h4 {
  @apply text-[22px];
}
.input-editor .menu-bar .menu-items .item-h5 {
  @apply text-[20px];
}
.input-editor .menu-bar .menu-items .item-h6 {
  @apply text-[18px];
}
.input-editor .menu-bar .table-menu-options div button {
  @apply bg-gray-300 whitespace-nowrap p-1 rounded text-[10px] hover:bg-gray-600 hover:text-white;
}
.input-editor.input-editor-error .menu-bar {
  @apply border-x-red-600 border-t-red-600;
}
.input-editor .tiptap {
  @apply border p-5 rounded-b-lg h-80 max-h-80 overflow-auto outline-none;
}
.input-editor.input-editor-error .tiptap {
  @apply border-x-red-600 border-b-red-600;
}
.input-editor.input-editor-disabled .tiptap {
  @apply rounded-t-lg bg-gray-100 border-gray-300;
}
.input-editor .tiptap a {
  @apply cursor-pointer underline transition-all duration-300 ease-in hover:text-primary-500
}
.input-editor .tiptap pre {
  @apply bg-gray-400 text-black rounded-sm my-5 px-4 py-3;
}
.input-editor .tiptap blockquote {
  @apply border-l-2 border-gray-400 my-5 pl-4
}
.input-editor .tiptap hr {
  @apply border-t border-gray-400 my-5
}
.input-editor .tiptap ul, .input-editor .tiptap ol {
  @apply px-4 my-5 mr-4 ml-1
}
.input-editor .tiptap ul {
  @apply list-disc;
}
.input-editor .tiptap ol {
  @apply list-decimal;
}
.input-editor .tiptap table {
  @apply border-collapse m-0 overflow-hidden table-fixed w-full;
}
.input-editor .tiptap table td, 
.input-editor .tiptap table th {
  @apply border border-gray-400 box-border min-w-[1em] py-1.5 px-2 relative align-top;
}
.input-editor .tiptap table td > *, 
.input-editor .tiptap table th > * {
  @apply mb-0;
}
.input-editor .tiptap table th {
  @apply bg-gray-400 font-bold text-left;
}
.input-editor .tiptap h1 {
  @apply text-5xl font-bold leading-loose;
}
.input-editor .tiptap h2 {
  @apply text-4xl font-semibold leading-loose;
}
.input-editor .tiptap h3 {
  @apply text-3xl font-semibold leading-loose;
}
.input-editor .tiptap h4 {
  @apply text-2xl font-semibold leading-relaxed;
}
.input-editor .tiptap h5 {
  @apply text-xl font-bold leading-relaxed;
}
.input-editor .tiptap h6 {
  @apply text-lg font-normal leading-normal;
}
.input-editor .tiptap code {
  @apply rounded text-sm py-1 px-1.5 bg-slate-400;
}

/* NUMERIC INPUT */
.custom-number-input input::-webkit-inner-spin-button,
.custom-number-input input::-webkit-outer-spin-button {
  @apply m-0 appearance-none
}
.custom-number-input input:focus, 
.custom-number-input button:focus {
  @apply !outline-none
}

/* SHORT INPUT BUTTON PAGE */
div.block.input-button div.flex.gap-4 {
  @apply gap-0 flex-row-reverse;
}
div.block.input-button div.flex.gap-4 label {
  @apply hidden
}
div.block.input-button div.flex.gap-4 .block.mb-3 {
  @apply mb-0;
}
div.block.input-button div.flex.gap-4 .block.mb-3:last-child div.input-loadmore__control {
  @apply rounded-r-none;
}
div.block.input-button div.flex.gap-4 .block.mb-3:first-child {
  @apply flex-[0];
}
div.block.input-button div.flex.gap-4 .block.mb-3:first-child button.relative {
  @apply rounded-l-none border-l-0 px-5;
}
div.block.input-button div.flex.gap-4 .block.mb-3:first-child button.relative span.block.truncate {
  @apply w-0 m-0;
}
div.block.input-button div.flex.gap-4 .block.mb-3:first-child div.relative ul.absolute {
  @apply right-0 min-w-[200px];
}

.textarea-auto {
  @apply min-h-[30px] overflow-hidden resize-none;
}

/* DYNAMIC CONTENT */
.dynamic-content > .flex > .block > div:first-child {
  @apply whitespace-nowrap;
}
.dynamic-content input[type=checkbox] ~ div {
  @apply mt-1 after:mt-1;
}

.input-select-flex > div {
  @apply flex gap-4;
}

/* Basics */
.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder *,
.mapboxgl-ctrl-geocoder *:after,
.mapboxgl-ctrl-geocoder *:before {
  box-sizing: border-box;
}

.mapboxgl-ctrl-geocoder {
  font-size: 18px;
  line-height: 24px;
  font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif;
  position: relative;
  background-color: #fff;
  width: 100%;
  min-width: 240px;
  z-index: 1;
  border-radius: 4px;
  transition: width .25s, min-width .25s;
}

.mapboxgl-ctrl-geocoder--input {
  font: inherit;
  width: 100%;
  border: 0;
  background-color: transparent;
  margin: 0;
  height: 50px;
  color: #404040; /* fallback */
  color: rgba(0, 0, 0, 0.75);
  padding: 6px 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mapboxgl-ctrl-geocoder--input::-ms-clear {
  display: none; /* hide input clear button in IE */
}

.mapboxgl-ctrl-geocoder--input:focus {
  color: #404040; /* fallback */
  color: rgba(0, 0, 0, 0.75);
  outline: 0;
  box-shadow: none;
  outline: thin dotted;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
  z-index: 2;
  position: absolute;
  right: 8px;
  top: 7px;
  display: none;
}

.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: 0 0 10px 2px rgba(0,0,0,.1);
}

/* Collapsed */
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
  width: 50px;
  min-width: 50px;
  transition: width .25s, min-width .25s;
}

/* Suggestions */
.mapboxgl-ctrl-geocoder .suggestions {
  background-color: #fff;
  border-radius: 4px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  top: 110%; /* fallback */
  top: calc(100% + 6px);
  z-index: 1000;
  overflow: hidden;
  font-size: 15px;
}

.mapboxgl-ctrl-bottom-left .suggestions,
.mapboxgl-ctrl-bottom-right .suggestions {
  top: auto;
  bottom: 100%;
}

.mapboxgl-ctrl-geocoder .suggestions > li > a {
  cursor: default;
  display: block;
  padding: 6px 12px;
  color: #404040;
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a,
.mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
  color: #404040;
  background-color: #f3f3f3;
  text-decoration: none;
  cursor: pointer;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
  font-weight: bold;
}

.mapboxgl-ctrl-geocoder--suggestion-title,
.mapboxgl-ctrl-geocoder--suggestion-address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Icons */
.mapboxgl-ctrl-geocoder--icon {
  display: inline-block;
  vertical-align: middle;
  speak: none;
  fill: #757575;
  top: 15px;
}

.mapboxgl-ctrl-geocoder--icon-search {
  position: absolute;
  top: 13px;
  left: 12px;
  width: 23px;
  height: 23px;
}

.mapboxgl-ctrl-geocoder--button {
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background: #fff;
  line-height: 1;
}

.mapboxgl-ctrl-geocoder--icon-close {
  width: 20px;
  height: 20px;
  margin-top: 8px;
  margin-right: 3px;
}

.mapboxgl-ctrl-geocoder--button:hover .mapboxgl-ctrl-geocoder--icon-close {
  fill: #909090;
}

.mapboxgl-ctrl-geocoder--icon-loading {
  width: 26px;
  height: 26px;
  margin-top: 5px;
  margin-right: 0px;
  -moz-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -webkit-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

/* Animation */
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Media queries*/
@media screen and (min-width: 640px) {

  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
    width: 36px;
    min-width: 36px;
  }

  .mapboxgl-ctrl-geocoder {
    width: 33.3333%;
    font-size: 15px;
    line-height: 20px;
    max-width: 360px;
  }
  .mapboxgl-ctrl-geocoder .suggestions {
    font-size: 13px;
  }

  .mapboxgl-ctrl-geocoder--icon {
    top: 8px;
  }

  .mapboxgl-ctrl-geocoder--icon-close {
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 0;
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    left: 7px;
    width: 20px;
    height: 20px;
  }

  .mapboxgl-ctrl-geocoder--input {
    height: 36px;
    padding: 6px 35px !important;
  }

  .mapboxgl-ctrl-geocoder--icon-loading {
    width: 26px;
    height: 26px;
    margin-top: -2px;
    margin-right: -5px;
  }

  .mapbox-gl-geocoder--error{
    color:#909090;
    padding: 6px 12px;
    font-size: 16px;
    text-align: center
  }
}

.input-map .mapboxgl-ctrl-bottom-left, 
.input-map .mapboxgl-ctrl-bottom-right {
  display: none;
}


.editor-dynamic h1 { 
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
}
.editor-dynamic h2 { 
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}
.editor-dynamic h3 { 
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}
.editor-dynamic p { 
  font-size: 14px;
  font-weight: 400;
}
.editor-dynamic a { 
  text-decoration: underline;
}